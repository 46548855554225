
import SocialLinks from '@/components/landing/SocialLinks.vue'
import Footer from '@/components/landing/Footer.vue'
import Logo from '@/components/common/Logo.vue'

export default {
    name: 'LandingFooter',
    components: { Footer, SocialLinks, Logo },
    props: {
        source: {
            type: String,
            default: null
        }
    }
}
